<template>
    <div></div>
</template>

<script>
export default {
  created() {
    // Captura la marca de tiempo cuando se carga el componente.
    this.startTime = performance.now()
    this.$ga.page(this.$route.path)
  },

  beforeDestroy() {
    if (this.startTime) {
      // Capture the end time when the component is destroyed.
      const endTime = performance.now()

      // Calculate the elapsed time in milliseconds.
      const elapsedTime = endTime - this.startTime

      // Send the timing event to Google Analytics as a timing event.
      const sendTimingEvent = () => {
        window.gtag("event", "timing_complete", {
          name: "Tiempo en página",
          value: elapsedTime
        })
        this.$ga.event('Interacción', 'Cierre de página', 'Aspirantes Page', elapsedTime)
      }

      // Use a debounce function to delay the sending of the timing event.
      const debounce = (func, delay) => {
        let timer
        return () => {
          clearTimeout(timer)
          timer = setTimeout(() => {
            func()
          }, delay)
        }
      }

      const sendTimingEventDebounced = debounce(sendTimingEvent, 1000)

      window.addEventListener("beforeunload", sendTimingEventDebounced)
    }
  },

  data() {
    return {
      startTime: null
    }
  }
}
</script>
