import getters from "./getter"
import actions from "./action"
import mutations from "./mutations"

const state = {
  categoryComponentReference: "favorites",
  categoryList: [
  ]
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
