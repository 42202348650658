<template>
  <button class="floating-button" @click="redirigir">?</button>
</template>

<script>
export default {
  methods: {
    redirigir() {
      window.open("https://admisionesuninorteco.zendesk.com/", "_blank")
    }
  }
}
</script>

<style scoped>
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 20px;
  background-color: var(--iq-primary-hover);
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}

.floating-button:hover {
  background-color: var(--iq-primary);
  transform: scale(1.1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
}
</style>
