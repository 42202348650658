<template>
  <div>
    <router-view />
    <FloatingButton />
    <TimeTracking />
  </div>
</template>
<script>
import { core } from "./config/pluginInit"
import FloatingButton from "./components/core/floatButton/HelpButton.vue"
import TimeTracking from "./TimeTracking.vue"

export default {
  name: "App",
  components: { FloatingButton, TimeTracking },
  created() {
    this.$store.dispatch("Setting/tryLogin")
  },
  mounted() {
    core.mainIndex()
  }
}
</script>
<style lang="scss">
@import "assets/scss/style.scss";
</style>
