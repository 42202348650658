export default {
  getVideo(context, payload) {
    const db = payload.firebase.firestore()
    return db.collection("videos").where("id", "==", payload.id).get()
  },
  getVideosBySeries(context, payload) {
    const db = payload.firebase.firestore()
    return db.collection("videos").where("idSerie", "==", payload.id).get()
  },
  getFirstVideoOfSeries(context, payload) {
    const db = payload.firebase.firestore()
    return db
      .collection("videos")
      .where("idSerie", "==", payload.id)
      .limit(1)
      .get()
  },
  getAllVideos(context, payload) {
    const db = payload.firebase.firestore()
    return db.collection("videos").get()
  },
  getFavoriteCategoryAction(context, payload) {
    const db = payload.firebase.firestore()
    const videosRef = db.collection("videos")
    return videosRef.orderBy("fecha", "desc").get()
  },
  addCategoryAction(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("addCategoryCommit", payload)
      resolve()
    })
  },
  removeCategoryAction(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("removeCategoryCommit", payload)
      resolve()
    })
  },
  updateCategoryAction(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("updateCategoryCommit", payload)
      resolve()
    })
  },

  getRegistrationVideoById(context, payload) {
    const db = payload.firebase.firestore()
    const videosRef = db.collection("videos")
    const videoId = payload.videoId // Assuming you pass the video ID as part of the payload
    // Use the doc() method to reference a specific document
    const videoRegistration = videosRef.doc(videoId).collection("registro")
    // Return the specific document
    return videoRegistration.get()
  },

  getCreditosVideoById(context, payload) {
    const db = payload.firebase.firestore()
    const videosRef = db.collection("videos")
    const videoId = payload.videoId // Assuming you pass the video ID as part of the payload
    const videoCreditos = videosRef.doc(videoId).collection("creditos")
    return videoCreditos.get()
  },

  getEnlacesVideoById(context, payload) {
    const db = payload.firebase.firestore()
    const videosRef = db.collection("videos")
    const videoId = payload.videoId // Assuming you pass the video ID as part of the payload
    const videoEnlaces = videosRef.doc(videoId).collection("enlaces")
    return videoEnlaces.get()
  }
}
