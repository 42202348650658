import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store/index"
const FrontendLayout = () => import("../layouts/FrontendLayout")
const Default = () => import("../layouts/BlankLayout")
const AuthLayout = () => import("../layouts/AuthLayout")
/* Authentic View */
const SignIn1 = () => import("../views/AuthPages/Default/SignIn1")
const SignUp1 = () => import("../views/AuthPages/Default/SignUp1")
const RecoverPassword1 = () =>
  import("../views/AuthPages/Default/RecoverPassword1")
const ConfirmMail1 = () => import("../views/AuthPages/Default/ConfirmMail1")
/* Extra Pages */
const ErrorPage = () => import("../views/Pages/ErrorPage")
const ComingSoon = () => import("../views/Pages/ComingSoon")
const Maintenance = () => import("../views/Pages/Maintenance")

/* Show Routes */
const LandingPage = () => import("../views/FrontendPages/MainPage.vue")
const CategoryDetail = () => import("../views/FrontendPages/ShowDetail.vue")
const Search = () => import("../views/FrontendPages/Search")
const AllVideos = () => import("../views/FrontendPages/AllVideos")

const ManageProfile = () =>
  import("../views/FrontendPages/UserProfile/ManageProfile.vue")
const Settings = () =>
  import("../views/FrontendPages/UserProfile/Settings.vue")
const TestVocacional = () =>
  import("../views/FrontendPages/TestVocacional/TestVocacional.vue")
const ResultadosTest = () =>
  import("../views/FrontendPages/TestVocacional/ResultadosTest.vue")
const AsesorFinanciero = () => import("../views/FrontendPages/AsesorFinanciero/AsesorFinanciero.vue")

Vue.use(VueRouter)

const authChildRoutes = (prop) => [
  {
    path: "sign-in1",
    name: prop + ".sign-in1",
    meta: { auth: true },
    component: SignIn1
  },
  {
    path: "sign-up1",
    name: prop + ".sign-up1",
    meta: { auth: true },
    component: SignUp1
  },
  {
    path: "password-reset1",
    name: prop + ".password-reset1",
    meta: { auth: true, mainClass: "" },
    component: RecoverPassword1
  },
  {
    path: "confirm-mail1",
    name: prop + ".confirm-mail1",
    meta: { auth: true },
    component: ConfirmMail1
  }
]

const pagesChildRoutes = (prop) => [
  {
    path: "error/:code",
    name: prop + ".error",
    meta: { auth: true },
    component: ErrorPage
  },
  {
    path: "coming-soon",
    name: prop + ".coming-soon",
    meta: { auth: true },
    component: ComingSoon
  },
  {
    path: "maintenance",
    name: prop + ".maintenance",
    meta: { auth: true },
    component: Maintenance
  }
]

const landingPageRoutes = (prop) => [
  {
    path: "",
    name: prop + ".landing-page",
    meta: { auth: true, name: "landing page 1", slider: "true" },
    component: LandingPage
  },
  {
    path: "/category-detail",
    name: prop + ".category-detail",
    meta: { auth: true, name: "Category Details" },
    component: CategoryDetail
  },
  {
    path: "/search",
    name: prop + ".search",
    meta: { auth: true, name: "Search Page" },
    component: Search
  },
  {
    path: "/all-videos",
    name: prop + ".all-videos",
    meta: { auth: true, name: "All Videos" },
    component: AllVideos
  },
  {
    path: "/resultados-test",
    name: prop + ".resultados-test",
    meta: { auth: true, name: "Resultados Test" },
    component: ResultadosTest
  },
  {
    path: "/manage-profile",
    name: prop + ".manage-profile",
    meta: { auth: true, name: "Manage Profile", mainClass: "" },
    component: ManageProfile
  },
  {
    path: "/profile-setting",
    name: prop + ".profile-setting",
    meta: { auth: true, name: "Profile Setting", mainClass: "" },
    component: Settings
  },
  {
    path: "/test-profesional",
    name: prop + ".test-profesional",
    meta: {
      auth: true,
      requiresAuth: true,
      name: "Test Vocacional",
      mainClass: ""
    },
    component: TestVocacional
  },
  {
    path: "/asesor-financiero",
    name: prop + ".asesorfinanciero",
    meta: { auth: true, requiresAuth: true, name: "Asesor Financiero" },
    component: AsesorFinanciero
  }
]
const routes = [
  {
    path: "/",
    name: "landing-page",
    component: FrontendLayout,
    meta: { auth: true },
    children: landingPageRoutes("landing-page")
  },
  {
    path: "/auth",
    name: "auth1",
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes("auth1")
  },
  {
    path: "/pages",
    name: "pages",
    component: Default,
    meta: { auth: true },
    children: pagesChildRoutes("default")
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const isAuthenticated = store.state.Setting.authUser.auth
  if (requiresAuth && !isAuthenticated) {
    next("/auth/sign-in1")
  } else {
    next()
  }
})
export default router
