import Vue from "vue"
import "mutationobserver-shim"
import "./Utils/fliter"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Raphael from "raphael/raphael"
import "./plugins"
import "./registerServiceWorker"
import i18n from "./i18n"
import "./directives"
import firebase from "firebase/app"
import InstantSearch from "vue-instantsearch"
import VueAnalytics from "vue-analytics"
import TimeTracking from "./TimeTracking.vue"

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID
})

Vue.component("TimeTracking", TimeTracking)

Vue.use(InstantSearch)
global.Raphael = Raphael

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}
firebase.initializeApp(firebaseConfig)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app")

router.afterEach(() => {
  window.scrollTo({ top: 0, behavior: "auto" })
})
