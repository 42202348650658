export default {
  authUserCommit (state, data) {
    state.authUser = data
    console.log(data.user)
    localStorage.setItem("idToken", data.user.idToken)
    localStorage.setItem("expiresIn", data.user.expiresIn)
    localStorage.setItem("localId", data.user.localId)
    localStorage.setItem("refreshToken", data.user.refreshToken)
    localStorage.setItem("email", data.user.email)
    localStorage.setItem("authEmail", data.user.authEmail)
    localStorage.setItem("name", data.user.name)
    localStorage.setItem("lastName", data.user.lastName)
    localStorage.setItem("idType", data.user.idType)
    localStorage.setItem("idNumber", data.user.idNumber)
    localStorage.setItem("photoUrl", data.user.photoUrl)
    localStorage.setItem("providerLogin", data.user.providerLogin)
  },

  miniSidebarCommit (state, data) {
    state.miniSidebarMenu = data
  },
  activePageCommit (state, data) {
    state.activePage = data
  }
}
