export default {
  authUserAction (context, payload) {
    context.commit("authUserCommit", payload)
  },
  getUserEmailInfo (context, payload) {
    return payload.db
      .collection("usuarios")
      .where("authEmail", "==", payload.email)
      .where("providerLogin", "==", payload.providerId)
      .get()
  },
  tryLogin (context) {
    const idToken = localStorage.getItem("idToken")
    const expiresIn = localStorage.getItem("expiresIn")
    const localId = localStorage.getItem("localId")
    const refreshToken = localStorage.getItem("refreshToken")
    const email = localStorage.getItem("email")
    const authEmail = localStorage.getItem("authEmail")
    const name = localStorage.getItem("name")
    const lastName = localStorage.getItem("lastName")
    const idType = localStorage.getItem("idType")
    const idNumber = localStorage.getItem("idNumber")
    const photoUrl = localStorage.getItem("photoUrl")
    const providerLogin = localStorage.getItem("providerLogin")

    if (authEmail !== 'undefined' && authEmail !== '' && authEmail !== null && authEmail !== 'null') {
      context.commit("authUserCommit", {
        auth: true,
        authType: true,
        miniSidebarMenu: true,
        user: {
          idToken,
          expiresIn,
          localId,
          refreshToken,
          email,
          authEmail,
          name,
          lastName,
          idType,
          idNumber,
          photoUrl,
          providerLogin
        }
      })
    }
  },
  logout (context, { firebase }) {
    localStorage.removeItem("idToken")
    localStorage.removeItem("expiresIn")
    localStorage.removeItem("localId")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("email")
    localStorage.removeItem("authEmail")
    localStorage.removeItem("name")
    localStorage.removeItem("lastName")
    localStorage.removeItem("idType")
    localStorage.removeItem("idNumber")
    localStorage.removeItem("photoUrl")
    localStorage.removeItem("providerLogin")
    context.commit("authUserCommit", {
      auth: false,
      authType: false,
      miniSidebarMenu: false,
      user: {
        idToken: "",
        expiresIn: "",
        localId: "",
        refreshToken: "",
        email: "",
        name: "",
        lastName: "",
        idType: "",
        idNumber: "",
        photoUrl: "",
        providerLogin: ""
      }
    })
  },
  miniSidebarAction (context, payload) {
    return new Promise((resolve, reject) => {
      if (context.state.miniSidebarMenu) {
        context.commit("miniSidebarCommit", false)
        resolve(false)
      } else {
        context.commit("miniSidebarCommit", true)
        resolve(true)
      }
    })
  },
  activePageAction (context, payload) {
    context.commit("activePageCommit", payload)
  }
}
